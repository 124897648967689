import { post, get, put, del } from '../../request/http';

export function getBackRoleList(params) {
    return post('/cts/back/backRoleDO/list', params);
}

export function getBackDetail(params) {
    return get('/cts/back/backRoleDO/detail', params);
}

export function getAllBackResources() {
    return get('/cts/back/backRoleDO/allBackResources');
}

export function addBackRole(params) {
    return post('/cts/back/backRoleDO/add', params)
}

export function updateBackRole(params) {
    return put('/cts/back/backRoleDO/update', params)
}

export function deleteBackRole(params) {
    return del('/cts/back/backRoleDO/delete', params)
}