<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/sysManagement/sysRoleManagement' }">角色管理</el-breadcrumb-item>
        <el-breadcrumb-item>角色详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="detail-container">
      <div class="title">{{showEdit ? '修改角色信息' : '角色详情'}}</div>
      <div style="width: 500px;" v-if="!showEdit">
        <el-form ref="roleForm" class="role-form" label-width="120px">
          <el-form-item label="角色名称">
            {{roleName}}
          </el-form-item>
          <el-form-item label="角色描述">
            <div style="width: 380px;word-break:break-all;line-height:22px;">{{roleDesc}}</div>
          </el-form-item>
          <!-- <el-form-item label="选择部门">
            {{deptNameList.join('/')}}
          </el-form-item> -->
          <el-form-item label="关联权限">
            <el-tree :data="data" show-checkbox node-key="id" :default-checked-keys="detailCheckedKeys">
              <template #default="{ data }">
                <span>{{data.name}}</span>
              </template>
            </el-tree>
          </el-form-item>
        </el-form>
        <div class="btn-group">
          <div class="btn edit" @click="edit">编辑</div>
          <div class="btn del" @click="delRole">删除</div>
          <div class="btn back" @click="backToHome">返回</div>
        </div>
      </div>

      <div style="width: 500px;" v-else>
        <el-form ref="editRoleForm" :rules="rules" :model="editForm" class="role-form" label-width="120px">
          <el-form-item label="角色名称" prop="editRoleName">
            <el-input v-model="editForm.editRoleName" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" prop="editRoleDesc">
            <el-input v-model="editForm.editRoleDesc" maxlength="512"></el-input>
          </el-form-item>
          <!-- <el-form-item label="选择部门" prop="editDeptIdList">
            <el-select v-model="editForm.editDeptIdList" multiple placeholder="请选择部门" style="width: 100%;">
              <el-option v-for="item in deptList" :key="item.deptId" :label="item.deptName" :value="item.deptId"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="关联权限">
            <template v-slot:label><span style="color: rgb(245, 108, 108);">*</span> 关联权限</template>
            <el-tree ref="rightTree" :data="data" show-checkbox node-key="id" :default-checked-keys="detailCheckedKeys">
              <template #default="{ data }">
                <span>{{data.name}}</span>
              </template>
            </el-tree>
            <span style="color: rgb(245, 108, 108);">{{showTip ? '请选择关联权限' : ''}}</span>
          </el-form-item>
        </el-form>

        <div class="btn-group">
          <div class="btn edit" @click="confirmEdit">确认修改</div>
          <div class="btn back" style="margin-left: 20px;" @click="back">返回</div>
        </div>
      </div>

    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { getBackDetail, getAllBackResources, updateBackRole, deleteBackRole } from "../../../api/sysManagement/role"
export default {
  setup() {
    const editRoleForm = ref();
    const rightTree = ref();
    //const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      showEdit: false,
      data: [],
      roleName: '',
      editForm: {
        editRoleName: '',
        editRoleDesc: '',
        //editDeptIdList: ''
      },
      roleId: '',
      roleDesc: '',
      deptNameList: [], //部门数组

      detailCheckedKeys: [],  //角色权限详情数组
      deptList: [],

      rules: {
        editRoleName: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        editRoleDesc: [{ required: true, message: "请输入角色描述", trigger: "blur" }],
        //editDeptIdList: [{ required: true, message: "请选择部门", trigger: "change" }]
      },
      showTip: false,

      deptIdArr: []
    })

    onMounted(() => {

      getDetailInfo().then(() => {
        getRightTree();
      })
      // getDeptList();
    })

    const getRightTree = () => {
      getAllBackResources().then((res) => {
        if (res.resultState === '200') {
          state.data = res.data;
          //设置权限树禁用
          setTreeDisabled(state.data);
        }
      }).catch((err) => {

      });
      // proxy.$get('/cts/back/authTree/detail').then((res) => {
      //   if (res.code === '200') {
      //     state.data = res.data;
      //     //设置权限树禁用
      //     setTreeDisabled(state.data);
      //   }
      // }).catch((err) => {

      // });
    }

    const setTreeDisabled = (data) => {
      data.forEach(item => {
        if (!state.showEdit) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        if (item.children) {
          setTreeDisabled(item.children)
        }
      })
    }

    // const getDeptList = () => {
    //   proxy.$get('/cts/back/department/deptAllList').then((res) => {
    //     if (res.code === '200') {
    //       state.deptList = res.data;
    //     }
    //   }).catch((err) => {

    //   });
    // }

    const getDetailInfo = () => {
      let params = {
        roleId: route.query.id
      }
      return new Promise((resolve, reject) => {
        getBackDetail({ params }).then((res) => {
          if (res.code === '200') {
            resolve(res.data);
            const { name, resourceId, roleId, description } = res.data;
            state.roleName = name;
            state.roleDesc = description;
            state.roleId = roleId;
            resourceId.split('/').forEach(item => {
              state.detailCheckedKeys.push(item)
            })
          }
        }).catch((err) => {

        });
      })
      // return new Promise((resolve, reject) => {
      //   proxy.$post('/cts/back/role/detail', params).then((res) => {
      //     if (res.code === '200') {
      //       state.deptIdArr = [];
      //       state.roleName = res.data.role.roleName;
      //       state.roleId = res.data.role.roleId;
      //       state.roleDesc = res.data.role.roleDesc;
      //       res.data.deptList.forEach(item => {
      //         state.deptNameList.push(item.deptName);
      //         state.deptIdArr.push(item.deptId)
      //         state.editForm.editDeptIdList = state.deptIdArr;
      //       });

      //       res.data.authList.forEach(item => {
      //         state.detailCheckedKeys.push(item.resourceId)
      //       })


      //       resolve();
      //     }
      //   }).catch((err) => {

      //   });
      // })

    }

    const confirmEdit = () => {
      state.showTip = false;
      const nodes = rightTree.value.getCheckedNodes();
      const resourceType = [];
      nodes.forEach(item => {
        resourceType.push(item.type)
      })
      let params = {
        description: state.editForm.editRoleDesc,
        name: state.editForm.editRoleName,
        resourceId: rightTree.value.getCheckedKeys().toString(),
        resourceType: resourceType.join(','),
        roleId: state.roleId
      }
      console.log(params);

      editRoleForm.value.validate((valid) => {
        if (rightTree.value.getCheckedKeys().length === 0) {
          state.showTip = true;
          return;
        }
        if (valid) {
          updateBackRole(params).then((res) => {
            if (res.code === '200') {
              ElMessage({
                message: `${res.msg}`,
                type: 'success',
              })
              router.push({ path: '/sysManagement/sysRoleManagement' })
            }
          }).catch((err) => {

          });
          // proxy.$put('/cts/back/role/modify', params).then((res) => {
          //   if (res.code === '200') {
          //     ElMessage({
          //       message: `${res.msg}`,
          //       type: 'success',
          //     })
          //     router.push({ path: '/roleManagement' })
          //   }
          // }).catch((err) => {

          // });
        }
      })
    }

    const edit = () => {
      state.showEdit = true;
      state.editForm.editRoleName = JSON.parse(JSON.stringify(state.roleName));
      state.editForm.editRoleDesc = JSON.parse(JSON.stringify(state.roleDesc));
      //state.editForm.editDeptIdList = state.deptIdArr;
      setTreeDisabled(state.data)
    }

    const delRole = () => {
      let params = {
        roleId: route.query.id
      }
      deleteBackRole({ data: params }).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          router.push({ path: '/sysManagement/sysRoleManagement' })
        }
      }).catch((err) => {

      });
      // proxy.$del('/cts/back/role/delete', { data: params }).then((res) => {
      //   if (res.code === '200') {
      //     ElMessage({
      //       message: `${res.msg}`,
      //       type: 'success',
      //     })
      //     router.push({ path: '/sysManagement/sysRoleManagement' })
      //   }
      // }).catch((err) => {

      // });
    }

    const back = () => {
      state.showEdit = false;
      setTreeDisabled(state.data)
    }

    const backToHome = () => {
      router.push({ path: '/sysManagement/sysRoleManagement' })
    }

    return {
      ...toRefs(state),
      edit,
      delRole,
      back,
      backToHome,
      confirmEdit,
      rightTree,
      editRoleForm
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}
.role-form {
  margin-top: 20px;
}
.btn-group {
  display: flex;
  padding: 30px 0 0 30px;
  .btn {
    width: 80px;
    text-align: center;
    padding: 5px 0;
    border-radius: 3px;
    cursor: pointer;
    &.edit {
      background: rgb(56, 162, 138);
      color: #fff;
    }
    &.del {
      background: rgb(255, 127, 127);
      color: #fff;
      margin: 0 20px;
    }
    &.back {
      border: 1px solid #ddd;
    }
  }
}
</style>